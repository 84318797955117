import { Injectable } from '@angular/core';
import {
  Config,
  EntityResponse,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  Publisher,
  PublisherDetailView,
  PublisherFormView,
  PublishersChangeManagerResponse,
  PublishersData,
  Request
} from '../../core/interfaces';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SspService } from '../../core/services/ssp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublishersService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private sspService: SspService
  ) {}

  getPublishers(request: Request): Observable<PublishersData> {
    return this.http.get<PublishersData>(`${this.config.ssp_endpoint}/publishers/grid`, {
      params: this.sspService.prepareRequest(request)
    });
  }

  /**
   * Get publisher form data
   *
   * @param {Request} request
   */
  getPublisherForm(request: Request): Observable<PublisherFormView> {
    return this.http.get<PublisherFormView>(`${this.config.ssp_endpoint}/publisher/getForm`, {
      params: this.sspService.prepareRequest(request)
    });
  }

  /**
   * Get publisher detail data
   *
   * @param request
   */
  getPublisherDetails(request: Request): Observable<PublisherDetailView> {
    return this.http.get<PublisherDetailView>(`${this.config.ssp_endpoint}/publisher/get`, {
      params: this.sspService.prepareRequest(request)
    });
  }

  addPublisher(data: Publisher): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.ssp_endpoint}/publisher`, data, { headers });
  }

  editPublisher(data: Publisher): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.ssp_endpoint}/publisher`, data, { headers });
  }

  changePublishersStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.ssp_endpoint}/publishers/status`, data, { headers });
  }

  multiChangeManager(request: Request): Observable<PublishersChangeManagerResponse> {
    return this.http.post<PublishersChangeManagerResponse>(`${this.config.ssp_endpoint}/publisher/managers/update`, request);
  }

  togglePaid(request: Request): Observable<EntityResponse> {
    return this.http.post<EntityResponse>(`${this.config.ssp_endpoint}/publisher/paid`, request);
  }
}
