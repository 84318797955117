<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>
  <adxad-modal-header>
    <h2 adxadModalTitle>{{ (!isNewPayout ? 'title_editPayout' : 'title_orderPayout') | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content>
    <p *ngIf="outPayoutMethod()">Please add payout method in your payment details to withdraw funds.</p>

    <ng-container
      [formGroup]="form"
      *ngIf="!outPayoutMethod()"
    >
      <div class="row">
        <ng-container *ngIf="!isNewPayout && !roles.isPublisher()">
          <div class="field-group col-6">
            <adxad-label> {{ 'publisher' | transloco }} </adxad-label>

            {{ this.config.data.publisher }}
          </div>

          <div class="field-group col-6">
            <adxad-label> {{ 'manager' | transloco }} </adxad-label>

            {{ this.config.data.manager }}
          </div>
        </ng-container>

        <adxad-form-field class="field-group col-6">
          <adxad-date-picker
            label="{{ 'label_payoutDate' | transloco }}"
            placeholder="{{ 'placeholder_choosePayoutDate' | transloco }}"
            formControlName="payoutDate"
            [min]="minDate"
            [max]="maxDate"
          ></adxad-date-picker>
          <adxad-show-errors [control]="payoutDate"></adxad-show-errors>
        </adxad-form-field>

        <adxad-form-field class="field-group col-6 --with-loader">
          <adxad-loader
            class="amount-loader"
            [diameter]="20"
            *ngIf="isLoadingAmount"
          ></adxad-loader>

          <adxad-number-input
            formControlName="amount"
            label="{{ 'label_amount' | transloco }}"
            [prefix]="true"
            [readonly]="true"
          >
            <span class="material-icons adxad-prefix"> attach_money </span>
          </adxad-number-input>
          <adxad-show-errors [control]="form.get('amount')"></adxad-show-errors>

          <adxad-hint
            *ngIf="roles.isPublisher() && isAmountLess50"
            [isWarning]="true"
          >
            Sorry, the minimum payout amount is {{ 50 | currency }}, please choose another date
          </adxad-hint>

          <adxad-hint *ngIf="!roles.isPublisher() && maxAmount"> Available max value is {{ maxAmount }} </adxad-hint>
        </adxad-form-field>
      </div>

      <ng-container [formGroup]="paymentGroup">
        <div class="row">
          <adxad-form-field class="field-group col-6">
            <adxad-select
              formControlName="method"
              label="{{ 'paymentMethod' | transloco }}"
              [options]="dicts.payoutMethods.data"
            ></adxad-select>
            <adxad-show-errors [control]="method"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field
            class="field-group col-6"
            *ngIf="method.value !== 'transfer'"
          >
            <adxad-input
              formControlName="wallet"
              label="{{ 'wallet' | transloco }}"
            ></adxad-input>
            <adxad-show-errors [control]="paymentGroup.get('wallet')"></adxad-show-errors>
          </adxad-form-field>

          <ng-container *ngIf="method.value === 'transfer'">
            <adxad-form-field class="field-group col-6">
              <adxad-select
                formControlName="type"
                label="{{ 'paymentType' | transloco }}"
                [options]="dict.accountType"
              ></adxad-select>
              <adxad-show-errors [control]="paymentGroup.get('type')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group col-6"
              *ngIf="type.value === 'company'"
            >
              <adxad-input
                formControlName="companyName"
                label="{{ 'companyName' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="paymentGroup.get('companyName')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group {{
                type.value === 'individual' ? 'col-12' : 'col-6'
              }}"
            >
              <adxad-input
                formControlName="SWIFT"
                label="SWIFT"
              ></adxad-input>
              <adxad-show-errors [control]="paymentGroup.get('SWIFT')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="beneficiaryName"
                label="{{ 'beneficiaryName' | transloco }}"
              ></adxad-input>
              <adxad-show-errors
                [control]="
                  paymentGroup.get('beneficiaryName')
                "
              ></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="beneficiaryAddress"
                label="{{ 'beneficiaryAddress' | transloco }}"
              ></adxad-input>
              <adxad-show-errors
                [control]="
                  paymentGroup.get('beneficiaryAddress')
                "
              ></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="bankAddress"
                label="{{ 'bankAddress' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="paymentGroup.get('bankAddress')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="IBAN"
                label="IBAN"
              ></adxad-input>
              <adxad-show-errors [control]="paymentGroup.get('IBAN')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-12">
              <adxad-input
                formControlName="phoneNumber"
                label="{{ 'phoneNumber' | transloco }}"
                type="tel"
              ></adxad-input>
              <adxad-show-errors [control]="paymentGroup.get('phoneNumber')"></adxad-show-errors>
            </adxad-form-field>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>

    <button
      adxadButton
      (click)="addPayoutMethod()"
      *ngIf="outPayoutMethod()"
    >
      Add payout method
    </button>

    <adxad-submit-button
      [disabled]="
        !form.valid || !form.dirty || isLoadingSubmit || isAmountLess50
      "
      (click)="submit()"
      [isLoading]="isLoadingSubmit"
      *ngIf="!outPayoutMethod()"
    >
      {{ (isNewPayout ? 'create' : 'save') | transloco }}
    </adxad-submit-button>
  </adxad-modal-actions>
</ng-container>
