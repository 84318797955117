<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ isNewPublisher ? 'Create publisher' : 'Edit publisher' }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-tabs>
      <adxad-tab label="Main info">
        <div class="row">
          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="firstName"
              label="First name"
              placeholder="Enter first name"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('firstName')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="lastName"
              label="Last name"
              placeholder="Enter last name"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('lastName')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="username"
              label="Login"
              placeholder="Enter login"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('username')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="email"
              label="Email"
              placeholder="Enter email"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('email')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="password"
              [suffix]="true"
              label="Password"
              [placeholder]="isNewPublisher ? 'Password' : 'Change password'"
              [type]="showPassword ? 'text' : 'password'"
            >
              <span
                class="material-icons-outlined adxad-suffix"
                (click)="showPassword = !showPassword"
              >
                {{ showPassword ? 'visibility_off' : 'visibility' }}
              </span>
            </adxad-input>
            <adxad-show-errors [control]="form.get('password')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field
            class="field-group col-6"
            *ngIf="roles.isAdmin()"
          >
            <adxad-select
              placeholder="Select account manager"
              formControlName="manager"
              label="Account manager"
              [options]="dicts.accountManagers.data"
              [filterable]="true"
              [lazy]="dicts.accountManagers.isLazy"
              (scrollOnBottom)="loadItems('accountManagers')"
              (selectOption)="filterItem('', 'accountManagers')"
              (filterValue)="
                filterItem($event, 'accountManagers')
              "
            ></adxad-select>
            <adxad-show-errors [control]="form.get('manager')"></adxad-show-errors>
          </adxad-form-field>

          <ng-container [formGroup]="tax">
            <adxad-form-field class="field-group col-6">
              <adxad-select
                placeholder="Select tax status"
                formControlName="type"
                label="Tax status"
                [options]="inlineDicts.taxStatus"
              ></adxad-select>
              <adxad-show-errors [control]="tax.get('type')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="vat"
                label="VAT / Tax ID"
                placeholder="Enter VAT or Tax ID"
              ></adxad-input>
              <adxad-show-errors [control]="tax.get('vat')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group col-12"
              *ngIf="tax.get('type').value === 2"
            >
              <adxad-input
                formControlName="companyName"
                label="Company name"
                placeholder="Enter company name"
              ></adxad-input>
              <adxad-show-errors [control]="tax.get('companyName')"></adxad-show-errors>
            </adxad-form-field>
          </ng-container>
        </div>
      </adxad-tab>

      <adxad-tab label="Contacts">
        <div
          class="row"
          [formGroup]="profile"
        >
          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="phoneNumber"
              label="Phone number"
              placeholder="Enter phone number"
              type="tel"
            ></adxad-input>
            <adxad-show-errors [control]="profile.get('phoneNumber')"></adxad-show-errors>
          </adxad-form-field>

          <ng-container formGroupName="address">
            <adxad-form-field class="field-group col-6">
              <adxad-select
                placeholder="Select country"
                formControlName="country"
                label="Country"
                [options]="dicts.countries.data"
                [lazy]="dicts.countries.isLazy"
                [filterable]="true"
                (scrollOnBottom)="loadItems('countries')"
                (selectOption)="filterItem('', 'countries')"
                (filterValue)="filterItem($event, 'countries')"
              ></adxad-select>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="city"
                label="City"
                placeholder="Enter city"
              ></adxad-input>
              <adxad-show-errors [control]="profile.get('address.city')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="zip"
                label="ZIP"
                placeholder="Enter zip code"
              ></adxad-input>
              <adxad-show-errors [control]="profile.get('address.zip')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-12">
              <adxad-input
                formControlName="address"
                label="Physical address"
                placeholder="Enter physical address"
              ></adxad-input>
              <adxad-show-errors [control]="profile.get('address.address')"></adxad-show-errors>
            </adxad-form-field>
          </ng-container>

          <adxad-form-field
            class="field-group col-12"
            formArrayName="messengers"
          >
            <adxad-messengers-select
              [label]="'messenger' | transloco"
              [messengers]="getMessengers"
              [options]="dicts.messengers.data"
            ></adxad-messengers-select>
          </adxad-form-field>
        </div>
      </adxad-tab>

      <adxad-tab label="Payment method">
        <ng-container [formGroup]="payment">
          <div class="row">
            <adxad-form-field class="field-group col-12">
              <adxad-select
                placeholder="Select payment method"
                formControlName="method"
                label="Payment method"
                [options]="dicts.paymentMethods.data"
              ></adxad-select>
              <adxad-show-errors [control]="payment.get('method')"></adxad-show-errors>
            </adxad-form-field>
          </div>

          <ng-container *ngIf="payment.get('method').value.length">
            <div class="row">
              <adxad-form-field
                class="field-group col-12"
                *ngIf="payment.get('method').value !== 'transfer'"
              >
                <adxad-input
                  formControlName="wallet"
                  label="Wallet"
                  placeholder="Enter wallet"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('wallet')"></adxad-show-errors>
              </adxad-form-field>
            </div>

            <div
              class="row"
              *ngIf="payment.get('method').value === 'transfer'"
            >
              <adxad-form-field class="field-group col-6">
                <adxad-select
                  placeholder="Select payment type"
                  formControlName="type"
                  label="Payment type"
                  [options]="inlineDicts.accountType"
                ></adxad-select>
                <adxad-show-errors [control]="payment.get('type')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field
                class="field-group col-6"
                *ngIf="payment.get('type').value === 'company'"
              >
                <adxad-input
                  formControlName="companyName"
                  label="Company name"
                  placeholder="Enter company name"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('companyName')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="SWIFT"
                  label="SWIFT"
                  placeholder="Enter SWIFT"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('SWIFT')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="beneficiaryName"
                  label="Beneficiary name"
                  placeholder="Enter beneficiary name"
                ></adxad-input>
                <adxad-show-errors
                  [control]="
                    payment.get('beneficiaryName')
                  "
                ></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="beneficiaryAddress"
                  label="Beneficiary address"
                  placeholder="Enter beneficiary address"
                ></adxad-input>
                <adxad-show-errors
                  [control]="
                    payment.get('beneficiaryAddress')
                  "
                ></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="bankAddress"
                  label="Bank address"
                  placeholder="Enter bank address"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('bankAddress')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="IBAN"
                  label="IBAN"
                  placeholder="Enter IBAN"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('IBAN')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="phoneNumber"
                  label="Phone number"
                  placeholder="Enter phone number"
                  type="tel"
                ></adxad-input>
                <adxad-show-errors [control]="payment.get('phoneNumber')"></adxad-show-errors>
              </adxad-form-field>
            </div>
          </ng-container>
        </ng-container>
      </adxad-tab>

      <adxad-tab label="Traffic filters">
        <adxad-traffic-filter
          [group]="$any(form.get('trafficFilter'))"
          config="spotTargeting"
        />
      </adxad-tab>
    </adxad-tabs>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      Cancel
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>

      <span [ngClass]="{ loading: isLoadingSubmit }"> {{ isNewPublisher ? 'Create' : 'Save' }} </span>
    </button>
  </adxad-modal-actions>
</ng-container>
