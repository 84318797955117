<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ 'edit' | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-tabs>
      <!--  Main info tab  -->
      <adxad-tab label="{{ 'mainInfo' | transloco }}">
        <div class="row">
          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="firstName"
              label="{{ 'firstName' | transloco }}"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('firstName')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field class="field-group col-6">
            <adxad-input
              formControlName="lastName"
              label="{{ 'lastName' | transloco }}"
            ></adxad-input>
            <adxad-show-errors [control]="form.get('lastName')"></adxad-show-errors>
          </adxad-form-field>

          <!--   Tax     -->
          <ng-container
            [formGroup]="tax"
            *ngIf="roles.isPublisher()"
          >
            <adxad-form-field class="field-group col-6">
              <adxad-select
                formControlName="type"
                label="{{ 'taxStatus' | transloco }}"
                [options]="dict.taxStatus"
              ></adxad-select>
              <adxad-show-errors [control]="tax.get('type')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="vat"
                label="{{ 'vatTax' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="tax.get('vat')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group col-12"
              *ngIf="tax.get('type').value === 2"
            >
              <adxad-input
                formControlName="companyName"
                label="{{ 'companyName' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="tax.get('companyName')"></adxad-show-errors>
            </adxad-form-field>
          </ng-container>
          <!--   End tax     -->

          <ng-container [formGroup]="profile">
            <adxad-form-field class="field-group col-12">
              <adxad-select
                [options]="filteredLanguages"
                (selectOption)="selectLang($event)"
                placeholder="{{ 'selectLanguage' | transloco }}"
                label="{{ 'language' | transloco }}"
                [lazy]="dicts.languages.isLazy"
                [filterable]="true"
                (scrollOnBottom)="loadItems('languages')"
                (filterValue)="filterItem($event, 'languages')"
              ></adxad-select>

              <ng-container *ngIf="selectedLangs.length">
                <adxad-chip-list class="languages-list">
                  <adxad-chip *ngFor="let lang of selectedLangs; let i = index">
                    {{ lang.value }}

                    <span
                      class="material-icons link"
                      (click)="removeLang(i)"
                    >
                      close
                    </span>
                  </adxad-chip>
                </adxad-chip-list>
              </ng-container>
            </adxad-form-field>
          </ng-container>
        </div>
      </adxad-tab>
      <!--  End main info tab  -->

      <!--  Contacts (country, city, zip, address, messengers)   -->
      <adxad-tab
        label="{{ 'contacts' | transloco }}"
        [formGroup]="profile"
      >
        <div class="row">
          <!--  Address   -->
          <ng-container *ngIf="roles.isPublisher()">
            <ng-container [formGroup]="address">
              <adxad-form-field class="field-group col-6">
                <adxad-select
                  formControlName="country"
                  label="{{ 'country' | transloco }}"
                  [options]="dicts.countries.data"
                  [lazy]="dicts.countries.isLazy"
                  [filterable]="true"
                  (scrollOnBottom)="loadItems('countries')"
                  (selectOption)="filterItem('', 'countries')"
                  (filterValue)="
                    filterItem($event, 'countries')
                  "
                ></adxad-select>
                <adxad-show-errors [control]="profile.get('country')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="city"
                  label="{{ 'city' | transloco }}"
                ></adxad-input>
                <adxad-show-errors [control]="profile.get('city')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="zip"
                  label="{{ 'zipCode' | transloco }}"
                ></adxad-input>
                <adxad-show-errors [control]="profile.get('zip')"></adxad-show-errors>
              </adxad-form-field>

              <adxad-form-field class="field-group col-6">
                <adxad-input
                  formControlName="address"
                  label="{{ 'address' | transloco }}"
                ></adxad-input>
                <adxad-show-errors [control]="profile.get('address')"></adxad-show-errors>
              </adxad-form-field>
            </ng-container>
          </ng-container>
          <!-- End address   -->

          <!--  Phone number & messengers   -->
          <ng-container [formGroup]="profile">
            <adxad-form-field class="field-group col-12">
              <adxad-input
                formControlName="phoneNumber"
                label="{{ 'phoneNumber' | transloco }}"
                type="tel"
              ></adxad-input>
              <adxad-show-errors [control]="profile.get('phoneNumber')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group col-12"
              formArrayName="messengers"
            >
              <adxad-messengers-select
                [label]="'messenger' | transloco"
                [messengers]="getMessengers"
                [options]="dicts.messengers.data"
              ></adxad-messengers-select>
            </adxad-form-field>
          </ng-container>
          <!--  End phone number & messengers   -->
        </div>
      </adxad-tab>
      <!--  Contacts (country, city, zip, address, messengers)   -->

      <!--  Payment method   -->
      <adxad-tab
        label="{{ 'paymentMethod' | transloco }}"
        [formGroup]="payment"
        *ngIf="roles.isPublisher()"
      >
        <div class="row">
          <adxad-form-field
            class="field-group"
            [ngClass]="!payment.get('method').value ? 'col-12' : 'col-6'"
          >
            <adxad-select
              formControlName="method"
              label="{{ 'paymentMethod' | transloco }}"
              [options]="dicts.paymentMethods.data"
            ></adxad-select>
            <adxad-show-errors [control]="payment.get('method')"></adxad-show-errors>
          </adxad-form-field>

          <adxad-form-field
            class="field-group col-6"
            *ngIf="
              payment.get('method').value &&
              payment.get('method').value !== 'transfer'
            "
          >
            <adxad-input
              formControlName="wallet"
              label="{{ 'wallet' | transloco }}"
            ></adxad-input>
            <adxad-show-errors [control]="payment.get('wallet')"></adxad-show-errors>
          </adxad-form-field>

          <ng-container *ngIf="payment.get('method').value === 'transfer'">
            <adxad-form-field class="field-group col-6">
              <adxad-select
                formControlName="type"
                label="{{ 'paymentType' | transloco }}"
                [options]="dict.accountType"
              ></adxad-select>
              <adxad-show-errors [control]="payment.get('type')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group col-6"
              *ngIf="payment.get('type').value === 'company'"
            >
              <adxad-input
                formControlName="companyName"
                label="{{ 'companyName' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('companyName')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="SWIFT"
                label="SWIFT"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('SWIFT')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="beneficiaryName"
                label="{{ 'beneficiaryName' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('beneficiaryName')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="beneficiaryAddress"
                label="{{ 'beneficiaryAddress' | transloco }}"
              ></adxad-input>
              <adxad-show-errors
                [control]="
                  payment.get('beneficiaryAddress')
                "
              ></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="bankAddress"
                label="{{ 'bankAddress' | transloco }}"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('bankAddress')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field class="field-group col-6">
              <adxad-input
                formControlName="IBAN"
                label="IBAN"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('IBAN')"></adxad-show-errors>
            </adxad-form-field>

            <adxad-form-field
              class="field-group {{
                payment.get('type').value === 'company' ? 'col-12' : 'col-6'
              }}"
            >
              <adxad-input
                formControlName="phoneNumber"
                label="{{ 'phoneNumber' | transloco }}"
                type="tel"
              ></adxad-input>
              <adxad-show-errors [control]="payment.get('phoneNumber')"></adxad-show-errors>
            </adxad-form-field>
          </ng-container>
        </div>
      </adxad-tab>
      <!--  End payment method   -->
    </adxad-tabs>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>
    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>
      <span [ngClass]="{ loading: isLoadingSubmit }"> {{ 'save' | transloco }} </span>
    </button>
  </adxad-modal-actions>
</ng-container>
