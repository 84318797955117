import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { MessageService } from '../../../../core/services/message.service';
import { PublishersService } from '../../publishers.service';
import { RolesService } from '../../../../core/services/roles.service';
import { SspService } from '../../../../core/services/ssp.service';
import { forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Dicts, Messenger, PublisherFormView } from '../../../../core/interfaces';
import { AdxadValidators } from '../../../../core/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-publisher-form',
  templateUrl: './publisher-form.component.html',
  styleUrls: ['./publisher-form.component.scss']
})
export class PublisherFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public isNewPublisher = true;
  public showPassword = true;
  public stage = 1;

  public dicts = {} as Dicts;

  public inlineDicts = {
    accountType: [
      { id: 'individual', value: 'Individual' },
      { id: 'company', value: 'Company' }
    ],
    paymentMethods: [],
    taxStatus: [
      { id: '', value: 'None' },
      { id: 1, value: 'Individual' },
      { id: 2, value: 'Company' }
    ]
  };

  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private alerts: AlertsComponent,
    private messageService: MessageService,
    private publishersService: PublishersService,
    public roles: RolesService,
    private sspService: SspService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.isNewPublisher = false;
    }

    this.createForm();

    if (!this.isNewPublisher) {
      this.loadPublisherData();
    } else {
      this.loadDicts();
    }
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [{ value: '', disabled: !this.isNewPublisher }, [Validators.required, Validators.email, AdxadValidators.latinChars]],
      username: [{ value: '', disabled: !this.isNewPublisher }, [Validators.required, AdxadValidators.latinChars]],
      password: ['', [Validators.minLength(6)]],
      payment: this.fb.group({
        method: ['']
      }),
      profile: this.fb.group({
        phoneNumber: '',
        address: this.fb.group({
          country: '',
          zip: ['', [AdxadValidators.latinChars]],
          city: ['', [AdxadValidators.latinChars]],
          address: ['', [AdxadValidators.latinChars]]
        }),
        tax: this.fb.group({
          type: '',
          vat: ['', [AdxadValidators.latinChars]]
        }),
        messengers: this.fb.array([])
      }),
      trafficFilter: this.fb.group({
        dsps: this.fb.group({ value: [[]], mode: true })
      })
    });

    if (this.isNewPublisher) {
      this.form.get('password').setValidators([Validators.required, Validators.minLength(6)]);
      this.form.get('password').updateValueAndValidity();
    }

    if (!this.isNewPublisher) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }

    if (this.roles.isAdmin()) {
      const managerId = this.config.data?.managerId;
      this.form.addControl('manager', this.fb.control(managerId ? managerId : '', [Validators.required]));
    }

    this.tax
      .get('type')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: status => {
          if (status === '') {
            this.tax.get('vat').setValue('');
          }
          if (status === 2) {
            this.tax.addControl('companyName', this.fb.control(''));
            this.tax.get('companyName').setValidators([AdxadValidators.latinChars, Validators.required]);
          } else {
            this.tax.removeControl('companyName');
          }

          this.form.updateValueAndValidity();
        }
      });

    this.payment
      .get('method')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: method => {
          if (method === 'transfer') {
            this.payment.removeControl('wallet');

            ['SWIFT', 'beneficiaryName', 'beneficiaryAddress', 'bankAddress', 'IBAN', 'phoneNumber', 'type'].forEach(field => {
              const isValid = field !== 'phoneNumber' ? Validators.required : null;
              this.payment.addControl(field, this.fb.control('', isValid));
            });

            this.payment
              .get('type')
              .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(value => {
                if (value === 'company') {
                  this.payment.addControl('companyName', this.fb.control(''));
                  this.payment.get('companyName').setValidators([AdxadValidators.latinChars, Validators.required]);
                } else {
                  this.payment.removeControl('companyName');
                }

                this.form.updateValueAndValidity();
              });

            this.payment.get('type').setValue('individual');
          } else if (method) {
            this.payment.addControl('wallet', this.fb.control(''));
            ['SWIFT', 'beneficiaryName', 'beneficiaryAddress', 'bankAddress', 'IBAN', 'companyName', 'phoneNumber', 'type'].forEach(field =>
              this.payment.removeControl(field)
            );
          } else {
            [
              'wallet',
              'SWIFT',
              'beneficiaryName',
              'beneficiaryAddress',
              'bankAddress',
              'IBAN',
              'companyName',
              'phoneNumber',
              'type'
            ].forEach(field => this.payment.removeControl(field));
          }
        }
      });
  }

  get profile(): UntypedFormGroup {
    return this.form.get('profile') as UntypedFormGroup;
  }

  get tax(): UntypedFormGroup {
    return this.profile.get('tax') as UntypedFormGroup;
  }

  get address(): UntypedFormGroup {
    return this.profile.get('address') as UntypedFormGroup;
  }

  get payment(): UntypedFormGroup {
    return this.form.get('payment') as UntypedFormGroup;
  }

  get getMessengers(): UntypedFormArray {
    return this.profile.get('messengers') as UntypedFormArray;
  }

  /**
   * Add messenger to form
   */
  addMessenger(data?: Messenger): void {
    this.getMessengers.push(
      this.fb.group({
        type: data.type,
        info: [data.info, [Validators.required, AdxadValidators.latinChars, Validators.minLength(3)]]
      })
    );
  }

  /**
   * Load dictionaries
   * If error -> close modal
   */
  loadDicts(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const forkList = [
      this.sspService.getCountries(request),
      this.sspService.getPaymentDict(request),
      this.sspService.getMessengers(request)
    ];

    if (this.roles.isAdmin()) {
      forkList.push(this.sspService.getManagers(request));
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.isLoading = false;

          this.dicts.countries = results[0];
          this.dicts.paymentMethods = results[1];
          this.dicts.messengers = results[2];

          if (this.roles.isAdmin()) {
            this.dicts.accountManagers = results[3];
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Load publisher data
   */
  loadPublisherData() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.publishersService
      .getPublisherForm({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: PublisherFormView) => {
          if (result.status === 'OK') {
            this.isLoading = false;
            this.form.patchValue(result.data);

            if (result.data.profile.messengers.length) {
              result.data.profile.messengers.forEach(x => this.addMessenger(x));
            }

            this.markFormGroupTouched(this.form);
            this.loadDicts();
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Load dicts
   */
  loadItems(dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    const method = dictId === 'countries' ? this.sspService.getCountries(request) : this.sspService.getManagers(request);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        dict.isLoading = false;
        dict.data = dict.data.concat(result.data);

        if (result.meta) {
          dict.meta = result.meta;
          dict.isLazy = dict.meta.total > dict.data.length;
        }
      },
      error: () => (dict.isLoading = false)
    });
  }

  /**
   * Load filtered dicts
   *
   * @param {string} value
   * @param dictId
   */
  filterItem(value: string, dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;

    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    const method = dictId === 'countries' ? this.sspService.getCountries(request) : this.sspService.getManagers(request);

    method.pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        dict.isLoading = false;
        dict.data = result.data;

        if (result.meta) {
          dict.meta = result.meta;
          dict.isLazy = dict.meta.total > dict.data.length;
        }
      },
      error: () => (dict.isLoading = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }
    this.isLoadingSubmit = true;

    const data = this.form.value;
    const method = this.isNewPublisher ? this.publishersService.addPublisher(data) : this.publishersService.editPublisher(data);

    if (data.profile.messengers.length) {
      data.profile.messengers = data.profile.messengers.filter(x => x.type !== '' && x.info !== '');
    }

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;

        if (response.status === 'OK') {
          const message = 'Publisher ' + (this.isNewPublisher ? 'created' : 'changed');
          this.messageService.add('reload-publishers-grid', { submit: true });

          if (this.config.data?.user) {
            this.messageService.add('reload-users-grid', { submit: true });
          }

          this.alerts.success(message, 3000);
          this.closeModal(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }
}
