import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { MessageService } from '../../../../core/services/message.service';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { Dicts, LazyLoadDict } from '../../../../core/interfaces';
import { RolesService } from '../../../../core/services/roles.service';
import { UserProfileFormComponent } from '../../../user-profile/components/user-profile-form/user-profile-form.component';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { DictsService } from '../../../../core/services/dicts.service';
import { SspService } from '../../../../core/services/ssp.service';
import { PayoutsService } from '../../payouts.service';
import { distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import dayjs from 'dayjs';
import { PublisherFormComponent } from '../../../publishers/components/publisher-form/publisher-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-payout-order-form',
  templateUrl: './payout-order-form.component.html',
  styleUrls: ['./payout-order-form.component.scss']
})
export class PayoutOrderFormComponent implements OnInit {
  form: UntypedFormGroup;
  isNewPayout = true;
  isLoading = false;
  isLoadingSubmit = false;
  isLoadingAmount = false;
  isAmountLess50 = false;

  dicts = {
    payoutMethods: { data: [] }
  } as Dicts;

  dict = {
    accountType: [
      { id: 'individual', value: 'Individual' },
      { id: 'company', value: 'Company' }
    ]
  };

  // Setup calendar
  minDate = dayjs().month().toString();
  maxDate = dayjs().subtract(1, 'days').toString();
  // End setup calendar

  maxAmount: number = null;

  private orderAmount = null;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private messageService: MessageService,
    private alerts: AlertsComponent,
    public roles: RolesService,
    private dictsService: DictsService,
    private sspService: SspService,
    private modal: AdxadModal,
    private payoutsService: PayoutsService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.edit) {
      this.isNewPayout = false;
    }

    this.createForm();
    this.loadPayoutData();
  }

  /**
   * Check user payout method
   */
  outPayoutMethod(): boolean {
    return this.isNewPayout && !this.config.data.payment.method;
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.isLoading = true;

    this.form = this.fb.group({
      amount: ['0', [Validators.required, Validators.min(1)]],
      payment: this.fb.group({
        method: ['']
      }),
      payoutDate: ['', [Validators.required]]
    });

    if (!this.isNewPayout) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }

    if (this.config.data.isManager) {
      this.form.addControl('owner', this.fb.control(this.config.data.id));
    }

    this.detectFormControlChanges();
  }

  /**
   * Detect value changes Payment method, Payment type, amount
   */
  detectFormControlChanges() {
    const transferControls = ['SWIFT', 'beneficiaryName', 'beneficiaryAddress', 'bankAddress', 'IBAN', 'phoneNumber', 'type'];
    const transferControlsWithWallet = [
      'wallet',
      'SWIFT',
      'beneficiaryName',
      'beneficiaryAddress',
      'bankAddress',
      'IBAN',
      'companyName',
      'phoneNumber',
      'type'
    ];
    const transferControlsWithCompanyName = [
      'SWIFT',
      'beneficiaryName',
      'beneficiaryAddress',
      'bankAddress',
      'IBAN',
      'companyName',
      'phoneNumber',
      'type'
    ];

    this.method.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: method => {
        if (method === 'transfer') {
          this.paymentGroup.removeControl('wallet');

          transferControls.forEach(field => {
            const isValid = field !== 'phoneNumber' ? [Validators.required] : null;
            this.paymentGroup.addControl(field, this.fb.control('', isValid));
          });

          this.type.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: value => {
              if (value === 'company') {
                this.paymentGroup.addControl('companyName', this.fb.control('', Validators.required));
              } else {
                this.paymentGroup.removeControl('companyName');
              }
            }
          });

          this.paymentGroup.get('type').setValue('individual');
        } else if (method) {
          this.paymentGroup.addControl('wallet', this.fb.control('', Validators.required));
          transferControlsWithCompanyName.forEach(field => this.paymentGroup.removeControl(field));
          this.paymentGroup.get('wallet').reset();
        } else {
          transferControlsWithWallet.forEach(field => this.paymentGroup.removeControl(field));
        }
      }
    });

    this.payoutDate.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.payoutDate.valid && this.payoutDate.touched),
        distinctUntilChanged(),
        tap(() => {
          if (this.roles.isPublisher()) {
            this.isLoadingAmount = true;
          }
        }),
        map(value => {
          if (!this.isNewPayout) {
            return {
              payoutDate: value,
              owner: this.config.data.publisherId,
              orderAmount: this.orderAmount
            };
          }
          if (this.config.data.isManager) {
            return {
              payoutDate: value,
              owner: this.config.data.id
            };
          }
          return {
            payoutDate: value
          };
        }),
        switchMap(value => this.payoutsService.getAmount(value))
      )
      .subscribe({
        next: ({ status, amount }) => {
          if (status === 'OK') {
            this.amount.patchValue(amount);
            this.maxAmount = amount;
            this.amount.setValidators([Validators.min(1), Validators.max(amount), Validators.required]);
            this.amount.updateValueAndValidity();
            this.isAmountLess50 = this.roles.isPublisher() && this.amount.value < 50;
            this.isLoadingAmount = false;
          }
        },
        error: () => (this.isLoadingAmount = false)
      });
  }

  get paymentGroup(): UntypedFormGroup {
    return this.form.get('payment') as UntypedFormGroup;
  }

  get method(): UntypedFormControl {
    return this.paymentGroup.get('method') as UntypedFormControl;
  }

  get type(): UntypedFormControl {
    return this.paymentGroup.get('type') as UntypedFormControl;
  }

  get amount(): UntypedFormControl {
    return this.form.get('amount') as UntypedFormControl;
  }

  get payoutDate(): UntypedFormControl {
    return this.form.get('payoutDate') as UntypedFormControl;
  }

  /**
   * Convert currency amount to number
   * @param value
   */
  formatMoney(value): number {
    const tmp = `${value}`.replace('$', '').replace(/\,/g, '');
    return Number(tmp);
  }

  /**
   * Load payout data
   */
  loadPayoutData() {
    if (!this.isNewPayout) {
      this.orderAmount = this.formatMoney(this.config.data.amount);
      this.payoutDate.patchValue(this.config.data.end_period);
      this.amount.patchValue(this.formatMoney(this.config.data.amount));
      this.amount.setValidators([Validators.max(this.formatMoney(this.config.data.amount)), Validators.min(1), Validators.required]);
    }
    this.paymentGroup.patchValue(this.config.data.payment);

    Object.values(this.paymentGroup.controls).forEach(control => {
      control.markAsTouched();
    });

    this.form.updateValueAndValidity();
    this.loadDicts();
  }

  /**
   * Load dictionaries
   * If error -> close modal
   */
  loadDicts(): void {
    const request = {
      limit: 200,
      page: 1
    };

    const paymentMethods = this.dictsService.isCashed('paymentMethod')
      ? this.dictsService.getDict$('paymentMethod', request)
      : this.sspService.getPaymentDict(request);

    paymentMethods.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: payouts => {
        this.dicts.payoutMethods = payouts as LazyLoadDict;
        this.dictsService.setDict('paymentMethod', payouts);
        setTimeout(() => (this.isLoading = false), 300);
      },
      error: () => this.closeModal()
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Open profile edit form
   */
  addPayoutMethod(): void {
    this.closeModal();

    const method = this.config.data.isManager ? PublisherFormComponent : UserProfileFormComponent;

    this.modal.open(method, {
      width: '680px',
      data: {
        id: this.config.data.id,
        managerId: this.roles.isAdmin() ? this.config.data.manager.id : ''
      }
    });
  }

  /**
   * Submit order payout form
   */
  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;

    const method = this.isNewPayout ? this.payoutsService.addPayout(data) : this.payoutsService.editPayout(data);

    method
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.isLoadingSubmit = false))
      )
      .subscribe({
        next: ({ status }) => {
          if (status === 'OK') {
            this.isLoadingSubmit = false;
            this.alerts.success('Payout successfully ' + (this.isNewPayout ? 'created' : 'changed'), 3000);
            if (this.config.data.isManager) {
              this.messageService.add('reload-publishers-grid', {
                submit: true
              });
              this.messageService.add('reload-publisher-view', {
                submit: true,
                value: this.config.data.id
              });
            } else {
              this.messageService.add('reload-payouts-grid', { submit: true });
            }
            this.closeModal(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
