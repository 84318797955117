import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { SspService } from '../../../../core/services/ssp.service';
import { Dict, Dicts, Messenger } from '../../../../core/interfaces';
import { forkJoin } from 'rxjs';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { RolesService } from '../../../../core/services/roles.service';
import { takeUntil } from 'rxjs/operators';
import { AdxadValidators } from '../../../../core/validators';
import { ProfileService } from '../../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public user;
  public dicts = {} as Dicts;
  public dict = {
    taxStatus: [
      { id: '', value: 'None' },
      { id: 1, value: 'Individual' },
      { id: 2, value: 'Company' }
    ],
    accountType: [
      { id: 'individual', value: 'Individual' },
      { id: 'company', value: 'Company' }
    ]
  };
  public selectedLangs: Dict[] = [];

  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    private sspService: SspService,
    private alerts: AlertsComponent,
    public config: AdxadModalConfig,
    public roles: RolesService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadProfileData();
  }

  get profile(): UntypedFormGroup {
    return this.form.get('profile') as UntypedFormGroup;
  }

  get tax(): UntypedFormGroup {
    return this.profile.get('tax') as UntypedFormGroup;
  }

  get address(): UntypedFormGroup {
    return this.profile.get('address') as UntypedFormGroup;
  }

  get payment(): UntypedFormGroup {
    return this.form.get('payment') as UntypedFormGroup;
  }

  get getMessengers(): UntypedFormArray {
    return this.profile.get('messengers') as UntypedFormArray;
  }

  /**
   * @return {UntypedFormControl} languages form control
   */
  get languages(): UntypedFormControl {
    return this.profile.get('languages') as UntypedFormControl;
  }

  /**
   * @return dict of not selected languages
   */
  get filteredLanguages(): Dict[] {
    return this.dicts.languages.data.filter(x => !x.selected);
  }

  /**
   * Load profile form data
   */
  loadProfileData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService
      .getProfileForm()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          const profileData = result.data;

          if (profileData.profile.languages.length) {
            this.selectedLangs = profileData.profile.languages as Dict[];
            profileData.profile.languages = profileData.profile.languages.map(x => x.id);
          }

          this.form.patchValue(profileData);

          if (profileData.profile.messengers.length) {
            profileData.profile.messengers.forEach(x => this.addMessenger(x));
          }

          this.loadData();
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Load dicts
   */
  loadItems(dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    const method = dictId === 'countries' ? this.sspService.getCountries(request) : this.sspService.getLanguages(request);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        dict.isLoading = false;
        dict.data = dict.data.concat(result.data);

        if (result.meta) {
          dict.meta = result.meta;
          dict.isLazy = dict.meta.total > dict.data.length;
        }
      },
      error: () => (dict.isLoading = false)
    });
  }

  /**
   * Load filtered dicts
   *
   * @param {string} value
   * @param dictId
   */
  filterItem(value: string, dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;
    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    const method = dictId === 'countries' ? this.sspService.getCountries(request) : this.sspService.getLanguages(request);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        dict.isLoading = false;
        dict.data = result.data;

        if (result.meta) {
          dict.meta = result.meta;
          dict.isLazy = dict.meta.total > dict.data.length;
        }
      },
      error: () => (dict.isLoading = false)
    });
  }

  /**
   * Add id of lang in language control
   *
   * @param {string} id
   */
  selectLang(id: string): void {
    const value = [...this.languages.value];

    if (value.indexOf(id) !== -1) {
      return;
    }

    const obj = this.dicts.languages.data.find(x => x.id === id);
    this.selectedLangs.push(obj);

    value.push(id);
    this.languages.setValue(value);
    this.form.markAsDirty();
  }

  /**
   * Remove language from control
   *
   * @param {number} i
   */
  removeLang(i: number): void {
    const value = [...this.languages.value];
    value.splice(i, 1);
    this.languages.setValue(value);
    this.selectedLangs.splice(i, 1);
    this.form.markAsDirty();
  }

  /**
   * Add messenger to form
   *
   * @param {Messenger} data
   */
  addMessenger(data?: Messenger): void {
    this.getMessengers.push(
      this.fb.group({
        type: data.type,
        info: [data.info, [Validators.required, AdxadValidators.latinChars, Validators.minLength(3)]]
      })
    );
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      profile: this.fb.group({
        phoneNumber: '',
        messengers: this.fb.array([]),
        languages: [[]]
      })
    });

    if (this.roles.isPublisher) {
      this.profile.addControl(
        'address',
        this.fb.group({
          country: '',
          zip: '',
          city: '',
          address: ''
        })
      );

      this.profile.addControl(
        'tax',
        this.fb.group({
          type: '',
          vat: ['', [Validators.minLength(3)]]
        })
      );

      this.form.addControl('payment', this.fb.group({ method: [''] }));

      this.tax
        .get('type')
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: status => {
            if (status === 2) {
              this.tax.addControl('companyName', this.fb.control('', Validators.required));
            } else {
              this.tax.removeControl('companyName');
            }
          }
        });

      this.payment
        .get('method')
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: method => {
            if (method === 'transfer') {
              this.payment.removeControl('wallet');
              ['SWIFT', 'beneficiaryName', 'beneficiaryAddress', 'bankAddress', 'IBAN', 'phoneNumber', 'type'].forEach(field => {
                const isValid = field !== 'phoneNumber' ? [Validators.required] : null;
                this.payment.addControl(field, this.fb.control('', isValid));
              });

              this.payment
                .get('type')
                .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                  next: value => {
                    if (value === 'company') {
                      this.payment.addControl('companyName', this.fb.control('', Validators.required));
                    } else {
                      this.payment.removeControl('companyName');
                    }
                  }
                });

              this.payment.get('type').setValue('individual');
            } else if (method) {
              this.payment.addControl('wallet', this.fb.control(''));
              ['SWIFT', 'beneficiaryName', 'beneficiaryAddress', 'bankAddress', 'IBAN', 'companyName', 'phoneNumber', 'type'].forEach(
                field => this.payment.removeControl(field)
              );
              this.payment.get('wallet').reset();
            } else {
              [
                'wallet',
                'SWIFT',
                'beneficiaryName',
                'beneficiaryAddress',
                'bankAddress',
                'IBAN',
                'companyName',
                'phoneNumber',
                'type'
              ].forEach(field => this.payment.removeControl(field));
            }
          }
        });
    }
  }

  /**
   * Load dictionaries
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const langRequest = {
      limit: 200,
      page: 1,
      ids: [...this.profile.get('languages').value]
    };

    const forkList = [
      this.sspService.getMessengers(request),
      this.sspService.getLanguages(langRequest),
      this.sspService.getPaymentDict(request)
    ];

    if (this.roles.isPublisher()) {
      forkList.push(this.sspService.getCountries(request));
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.isLoading = false;
          this.dicts.messengers = results[0];
          this.dicts.languages = results[1];
          this.dicts.paymentMethods = results[2];
          this.dicts.languages.data.forEach(x => (x.selected = false));

          if (this.roles.isPublisher()) {
            this.dicts.countries = results[3];
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  submit(): void {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;

    const data = this.form.value;

    if (data['profile']['languages'].length) {
      data['profile']['languages'].sort();
    }

    this.profileService
      .changeProfile(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoadingSubmit = false;

          if (result.status === 'OK') {
            this.profileService.setProfile(result.data.user);
            this.alerts.success('Profile changed', 3000);
            this.closeModal(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
